import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import BaseTable from "./BaseTable"
import View from "./cells/ViewCell";

import General from "../../../utils/General";
import Currency from "../../../utils/Currency";

const TYPE_FILTER = {
  name: {
    api: 'type',
    label: 'Type',
  },
  values: [
    {
      label: "OSS",
      value: "oss"
    },
    {
      label: "IOSS",
      value: "ioss"
    },
    {
      label: "Local",
      value: "local"
    }
  ]
}

const STATUS_FILTER = {
  name: {
    api: 'status',
    label: 'Status',
  },
  values: [
    {
      label: "Open",
      value: "open"
    },
    {
      label: "Filed",
      value: "filed"
    }
  ]
}

const PAYMENT_STATUS_FILTER = {
  name: {
    api: 'payment',
    label: 'Payment Status',
  },
  values: [
    {
      label: "Paid",
      value: "paid"
    },
    {
      label: "Refunded",
      value: "refunded"
    },
    {
      label: "Awaiting Payment",
      value: "awaiting_payment"
    }
  ]
}

const FILTERS = [TYPE_FILTER, STATUS_FILTER, PAYMENT_STATUS_FILTER]

class Filings extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }

    this.table = React.createRef()
  }

  _getColumns(){
    let columns = [
      {
        Header: 'Period',
        id: 'created_at',
        width: 100,
        Cell: rowInfo => {
          let filing = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a className="text-dark fw-bolder text-hover-primary fs-6">{filing.period}</a>
                <span class="text-muted fw-bold text-muted d-block fs-7">{moment(filing.period_start).format('YYYY')}</span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Type',
        id: 'type',
        width: 100,
        Cell: rowInfo => {
          let filing = rowInfo.original
          let className = 'badge-light-dark'
          let label = `${filing.tax_number.country_code} ${filing.type.toUpperCase()}`
          if(filing.type === "oss"){
            className = 'badge-light-info'
          }else if(filing.type === "ioss"){
            className = 'badge-light-success'
          }
          if(filing.type === "oss" || filing.type === "ioss"){
            label = `${filing.type.toUpperCase()}`
          }
          return (
            <span class={`badge ${className} badge-sm fs-base m-lg-auto`}>
							{label}
						</span>
          )
        }
      },
      {
        Header: 'Sales',
        id: 'sales_total',
        Cell: rowInfo => {
          let filing = rowInfo.original
          let symbol = filing.currency.symbol
          return (
            <div class="d-flex align-items-center">
              <a class="text-dark fw-bolder text-hover-primary fs-6">
                {Currency.format(filing.currency.symbol, filing.sales_total)}
              </a>
            </div>
          )
        }
      },
      {
        Header: 'Tax On Sales',
        id: 'sales_tax',
        Cell: rowInfo => {
          let filing = rowInfo.original
          let symbol = filing.currency.symbol
          return (
            <div class="d-flex align-items-center">
              <a class="text-dark fw-bolder text-hover-primary fs-6">
                {Currency.format(filing.currency.symbol, filing.sales_tax)}
              </a>
            </div>
          )
        }
      },
      {
        Header: 'Purchases',
        id: 'purchases_total',
        Cell: rowInfo => {
          let filing = rowInfo.original
          let symbol = filing.currency.symbol
          return (
            <div class="d-flex align-items-center">
              <a class="text-dark fw-bolder text-hover-primary fs-6">
                {Currency.format(filing.currency.symbol, filing.purchases_total)}
              </a>
            </div>
          )
        }
      },
      {
        Header: 'Tax On Purchases',
        id: 'purchases_tax',
        Cell: rowInfo => {
          let filing = rowInfo.original
          let symbol = filing.currency.symbol
          return (
            <div class="d-flex align-items-center">
              <a class="text-dark fw-bolder text-hover-primary fs-6">
                {Currency.format(filing.currency.symbol, filing.purchases_tax)}
              </a>
            </div>
          )
        }
      },
      {
        Header: 'Net',
        id: 'net',
        Cell: rowInfo => {
          let filing = rowInfo.original
          let symbol = filing.currency.symbol
          let className = filing.net <= 0 ? "badge-light-primary" : "badge-light-danger"
          return (
            <span class={`badge ${className} badge-sm fs-base m-lg-auto`}>
  						{Currency.format(filing.currency.symbol, filing.net)}
  					</span>
          )
        }
      },
      {
        Header: 'Status',
        id: 'status',
        width: 100,
        Cell: rowInfo => {
          let filing = rowInfo.original
          let className = filing.status === "open" ? 'badge-light-dark' : 'badge-light-success'
          return (
            <span class={`badge ${className} badge-sm fs-base m-lg-auto`}>
              { filing.status?.toUpperCase() }
            </span>
          )
        }
      },
      {
        Header: 'Payment Status',
        id: 'payment_status',
        width: 150,
        Cell: rowInfo => {
          let filing = rowInfo.original
          let paymentStatus = filing.payment_status
          let className = ""


          if(paymentStatus == "paid"){
            className = 'badge-dark'
          }

          if(paymentStatus == "refunded"){
            className = 'badge-primary'
          }

          if(paymentStatus == "awaiting_payment"){
            className = "badge-warning"
          }

          if(filing.status === "open"){
            return "-"
          }

          return (
            <span class={`badge ${className} badge-sm fs-base m-lg-auto`}>
              { General.snakeCaseToTitleCase(paymentStatus).toUpperCase() }
            </span>
          )
        }
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        width: 60,
        Cell: rowInfo => {
          let filing = rowInfo.original
          return (
            <div className={"my-auto float-end"}>
              <View
                tooltip={"View"}
                onViewClicked={() => {
                  window.location.href = `/filing/${filing.id}`
                }}
              />
            </div>
          )
        }
      }
    ]

    return columns
  }

  render() {
    let {

    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Filings}`}
          noDataMessage={"No filings found"}
          title={"Filings"}
          columns={columns}
          filters={FILTERS}
          showSearch={false}
          dateRange={true}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
        />
      </>
    )

  }

}

export default withRouter(Filings);
