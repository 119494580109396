import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import AuthManager from "../../../utils/AuthManager";


class Header extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthManager.currentUser
    }
  }

  _onLogoutPressed() {
    AuthManager.logout().then(() => {
      this.props.history.push('/login')
    })
  }

  render() {
    let {
      currentUser
    } = this.state

    let email = currentUser.user.email
    let username = currentUser.user.first_name

    return (
      <>
        <div id="kt_header" style={{}} className="header align-items-stretch">
          <div className="container-fluid d-flex align-items-stretch justify-content-between">
            <div className="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
              <div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                   id="kt_aside_mobile_toggle">
                <span className="svg-icon svg-icon-2x mt-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                          fill="black"></path>
                    <path opacity="0.3"
                          d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                          fill="black"></path>
                  </svg>
                </span>
              </div>
            </div>

            <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
              <a href="javascript:;" className="d-lg-none">
                <img alt="Logo" src={window.General.Branding?.logos?.dark?.original} className="h-30px"/>
              </a>
            </div>

            <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
              <div className="d-flex align-items-stretch" id="kt_header_nav">

              </div>
              <div className="d-flex align-items-stretch flex-shrink-0">
                <div className="d-flex align-items-stretch flex-shrink-0">

                  <div className="d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
                    <div className="cursor-pointer symbol symbol-30px symbol-md-40px" data-kt-menu-trigger="click"
                         data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                      <span className="svg-icon svg-icon-lg svg-icon-primary">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                            <path
                              d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              opacity="0.3"
                            ></path>
                            <path
                              d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                              fill="#000000"
                              fillRule="nonzero"
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </div>
                    <div
                      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
                      data-kt-menu="true">
                      <div className="menu-item px-3">
                        <div className="menu-content d-flex align-items-center px-3">
                          <div
                            className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0"
                            style={{
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingTop: 5,
                              paddingBottom: 5,
                              marginRight: 10
                            }}
                          >
                            <span className="font-size-h3 font-weight-boldest">
                              {username.charAt(0)}
                            </span>
                          </div>
                          <div className="d-flex flex-column">
                            <div className="fw-bolder d-flex align-items-center fs-5">{username}</div>
                            <a href="#" className="fw-bold text-muted text-hover-primary fs-7 text-break">{email}</a>
                          </div>
                        </div>
                      </div>
                      <div className="separator my-2"></div>
                      <div className="menu-item px-5 my-1">
                        <Link
                          to="/messages"
                          className="menu-link px-5"
                        >
                          Messages
                        </Link>
                      </div>
                      <div className="separator my-2"></div>
                      <div className="menu-item px-5 my-1">
                        <Link
                          to="/settings"
                          className="menu-link px-5"
                        >
                          Account Settings
                        </Link>
                      </div>
                      <div className="menu-item px-5">
                        <a
                          href="javascript:;"
                          className="menu-link px-5"
                          onClick={e => {
                            e.preventDefault()
                            this._onLogoutPressed()
                          }}
                        >
                          Sign Out
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(Header)
