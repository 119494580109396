import React from "react"

import {Link} from "react-router-dom";

import Stats from "./components/Stats";
import Transactions from "./components/tables/Transactions";

import FilingOverview from "./components/filings/Overview";

import General from "../utils/General";
import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import Event from "../utils/Event";


export default class Dashboard extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser
    }
  }

  componentDidMount() {
    let onboardingStatus = this.state.currentUser.company.info.status
    if(onboardingStatus !== "complete"){
      window.location = "/register"
    }
  }

  render() {
    let {
      stats
    } = this.state

    return (
      <>

        <FilingOverview/>

        <Stats/>

        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <Transactions
              latestResults={true}
            />
          </div>
        </div>
      </>
    )
  }

}
