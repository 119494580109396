import React from "react"
import {Link} from "react-router-dom";
import ReactPasswordStrength from 'react-password-strength';

import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import Email from "../../../utils/Email";

export default class AccountDetails extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      data: props.data,
      packageObject: props.packageObject,
      isLoading: false,
      passwordVisible: false,
      passwordType: "password",
      confirmPasswordVisible : false
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _handleUserDetailsChange(e){
    let data = this.state.data
    data.members[0].user[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _isSignUpFormValid(){
    let {
      data,
      score
    } = this.state

    let error = null
    if(!data.name){
      error = "Please enter your company name"
    }
    else if(!data.members[0].user.first_name){
      error = "Please enter your first name"
    }
    else if(!data.members[0].user.last_name){
      error = "Please enter your last name"
    }
    else if(!Email.isValid(data.members[0].user.email)){
      error = "Please enter a valid email"
    }
    else if(!data.members[0].user.password.match(/^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/)) {
      error = "Please ensure your password contains letters, numbers and is at least 8 characters long"
    }
    else if(!data.members[0].user.confirmPassword) {
      error = "Please confirm your password"
    }
    else if(data.members[0].user.password !== data.members[0].user.confirmPassword) {
      error = "Please ensure the passwords match"
    }
    else if(!data.toc) {
      error = "Please agree to the terms and conditions."
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _register(){
    let {
      data,
      packageObject
    } = this.state

    if(!this._isSignUpFormValid()){
      return
    }

    if(packageObject){
      data.package = packageObject.id
    }

    if(window.General.Branding?.partner_id){
      data.partner = window.General.Branding.partner_id
    }

    this.props.onIsLoading(true)

    AuthManager.register(data)
      .then(response => {
        this.props.onIsLoading(false)
        this.props.onRegistered(response.company_member)
      })
      .catch(error => {
        this.props.onIsLoading(false)
        Notify.error(error.message)
      })
  }

  render() {
    let {
      data,
      isLoading,
      passwordType,
      packageObject
    } = this.state

    let title = 'Create an Account'
    if(packageObject){
      title = packageObject.title
    }

    return (
      <>
        <div className="w-lg-700px p-10 p-lg-15 mx-auto" data-select2-id="select2-data-14-gz4q">

          <form
            className="my-auto pb-5 fv-plugins-bootstrap5 fv-plugins-framework"
            noValidate="novalidate"
            id="kt_create_account_form"
            data-select2-id="select2-data-kt_create_account_form"
          >
            <div className="current" data-kt-stepper-element="content">
              <div className="bg-body rounded pt-10 px-10 mx-auto" style={{paddingBottom: 0}}>
                <div className="mb-10">
                  <h2 className="fw-bolder text-dark">{title}</h2>
                  <div className="text-gray-400 fw-bold fs-4">
                    Already have an account?
                    <Link to="/login" className="link-primary fw-bolder"> Sign in here</Link>
                  </div>
                </div>
                <div className="fv-row mb-7 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                  <label className="form-label fw-bolder text-dark fs-6">Company Name</label>
                  <input
                    name="business_name"
                    className="form-control form-control-lg form-control-solid"
                    value={data.name}
                    onChange={e => {
                      data.name = e.target.value
                      this.setState({data})
                    }}
                  />
                  <div className="fv-plugins-message-container invalid-feedback"/>
                </div>
                <div className="row mb-7 fv-row">
                  <div className="col-xl-6">
                    <label className="form-label fw-bolder text-dark fs-6">First Name</label>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      name="first_name"
                      value={data.members[0].user.first_name}
                      onChange={e => this._handleUserDetailsChange(e)}
                    />
                  </div>
                  <div className="col-xl-6">
                    <label className="form-label fw-bolder text-dark fs-6">Last Name</label>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      name="last_name"
                      value={data.members[0].user.last_name}
                      onChange={e => this._handleUserDetailsChange(e)}
                    />
                  </div>
                </div>
                <div className="mb-7 fv-row">
                  <label className="form-label fw-bolder text-dark fs-6">Email</label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="email"
                    value={data.members[0].user.email}
                    onChange={e => this._handleUserDetailsChange(e)}
                  />
                </div>
                <div className="mb-10 fv-row">
                  <div className="mb-1">
                    <label className="form-label fw-bolder text-dark fs-6">Password</label>
                    <div className="position-relative mb-3">
                      <input
                        className="form-control form-control-lg form-control-solid"
                        type={this.state.passwordVisible ? "text" : "password"}
                        placeholder=""
                        name="password"
                        autoComplete="off"
                        value={data.members[0].user.password}
                        onChange={e => this._handleUserDetailsChange(e)}
                      />
                      <span
                        className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                        data-kt-password-meter-control="visibility"
                        onClick={() => {
                          this.setState({passwordVisible: !this.state.passwordVisible})
                        }}
                      >
                        {
                          this.state.passwordVisible ?
                            <i className="bi bi-eye fs-2"></i>
                          :
                            <i className="bi bi-eye-slash fs-2"></i>
                        }
                      </span>
                    </div>
                  </div>
                  <div className="text-muted">
                    Use 8 or more characters with a mix of letters & numbers.
                  </div>
                  <div className="fv-plugins-message-container invalid-feedback"></div>
                </div>
                <div className="fv-row mb-5">
                  <label className="form-label fw-bolder text-dark fs-6">Confirm Password</label>
                  <div className="position-relative">
                    <input
                      className="form-control form-control-lg form-control-solid"
                      type={this.state.confirmPasswordVisible ? "text" : "password"}
                      placeholder=""
                      name="confirmPassword"
                      autoComplete="off"
                      value={data.members[0].user.confirmPassword}
                      onChange={e => this._handleUserDetailsChange(e)}
                    />
                    <span
                          className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                          data-kt-password-meter-control="visibility"
                          onClick={() => {
                            this.setState({confirmPasswordVisible: !this.state.confirmPasswordVisible})
                          }}
                        >
                          {
                            this.state.confirmPasswordVisible ?
                              <i className="bi bi-eye fs-2"></i>
                            :
                              <i className="bi bi-eye-slash fs-2"></i>
                          }
                    </span>
                  </div>
                </div>
                <div className="fv-row mb-10">
                  <label className="form-check form-check-custom form-check-solid form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="toc"
                      onChange={e => {
                        data.toc = e.target.checked
                        this.setState({data})
                      }}
                    />
                    <span className="form-check-label fw-bold text-gray-700 fs-6">
                      I agree to the
                      <a href="https://www.taxmatic.com/terms-and-conditions/" target="_blank" className="ms-1 link-primary"> Terms and Conditions</a>.
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="d-flex flex-center pt-15">
              <div className="mr-2"/>
              <button
                type="button"
                className="btn btn-lg btn-primary"
                data-kt-stepper-action="next"
                onClick={e => this._register()}
              >
                {packageObject?.cta || 'Continue'}
                <span className="svg-icon svg-icon-4 ms-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)"
                            fill="black"></rect>
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="black"></path>
                    </svg>
                  </span>
              </button>
            </div>
          </form>
        </div>
      </>
    )
  }

}
