import React from "react"

import Card from "./components/structure/Card";
import General from "./components/settings/General"
import Api from "./components/settings/Api"

import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";


export default class Settings extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser,
      selectedTab: "general"
    }
  }

  componentDidMount() {

  }

  render() {
    let {
      partner,
      currentUser,
      partnerInfo,
      partners,
      subscription,
      selectedTab
    } = this.state

    let name = `${currentUser.user.first_name} ${currentUser.user.last_name}`

    return (
      <>
        <div className="card mb-5 mb-xl-10">
          <div className="card-body pt-9 pb-0">
            <div className="d-flex overflow-auto h-55px">
              <ul
                className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
              >
                <li className="nav-item">
                  <a className={`nav-link text-active-primary me-6 ${selectedTab === 'general' ? 'active' : ''}`} data-bs-toggle="tab" href="#general">
                    Profile
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link text-active-primary me-6 ${selectedTab === 'api' ? 'active' : ''}`} data-bs-toggle="tab" href="#api">
                    Api
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="tab-content" id="analytics">
          <div className={`tab-pane fade ${selectedTab === 'general' ? 'show active' : ''}`} id="general" role="tabpanel">
            <General/>
          </div>
          <div className={`tab-pane fade ${selectedTab === 'api' ? 'show active' : ''}`} id="api" role="tabpanel">
            <Api/>
          </div>
        </div>
      </>
    )
  }

}
