import React from "react"
import {Link} from "react-router-dom";

export default class PackageDetail extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props)
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      activeTabIndex: props.activeTabIndex,
      packageObject: props.packageObject,
    }
  }

  _renderSteps(){
    let {
      activeTabIndex
    } = this.state

    return (
      <div className="stepper-nav">

        <div className={`stepper-item ${activeTabIndex === 0 ? "current" : activeTabIndex > 0 ? "completed" : "pending"}`} data-kt-stepper-element="nav">
          <div className="stepper-line w-40px"></div>
          <div className="stepper-icon w-40px h-40px">
            <i className="stepper-check fas fa-check"></i>
            <span className="stepper-number">1</span>
          </div>
          <div className="stepper-label">
            <h3 className="stepper-title">Account Info</h3>
            <div className="stepper-desc fw-bold">Setup Your Account</div>
          </div>
        </div>

        <div className={`stepper-item ${activeTabIndex === 1 ? "current" : activeTabIndex > 1 ? "completed" : "pending"}`} data-kt-stepper-element="nav">
          <div className="stepper-line w-40px"></div>
          <div className="stepper-icon w-40px h-40px">
            <i className="stepper-check fas fa-check"></i>
            <span className="stepper-number">2</span>
          </div>
          <div className="stepper-label">
            <h3 className="stepper-title">Tax Info</h3>
            <div className="stepper-desc fw-bold">Your Tax Related Info</div>
          </div>
        </div>

        <div className={`stepper-item ${activeTabIndex === 2 ? "current" : activeTabIndex > 2 ? "completed" : "pending"}`} data-kt-stepper-element="nav">
          <div className="stepper-line w-40px"></div>
          <div className="stepper-icon w-40px h-40px">
            <i className="stepper-check fas fa-check"></i>
            <span className="stepper-number">3</span>
          </div>
          <div className="stepper-label">
            <h3 className="stepper-title">Company Details</h3>
            <div className="stepper-desc fw-bold">Tell us about your company</div>
          </div>
        </div>

        <div className={`stepper-item ${activeTabIndex === 3 ? "current" : activeTabIndex > 3 ? "completed" : "pending"}`} data-kt-stepper-element="nav">
          <div className="stepper-line w-40px"></div>
          <div className="stepper-icon w-40px h-40px">
            <i className="stepper-check fas fa-check"></i>
            <span className="stepper-number">4</span>
          </div>
          <div className="stepper-label">
            <h3 className="stepper-title">Store</h3>
            <div className="stepper-desc fw-bold">Connect your store</div>
          </div>
        </div>

        <div className={`stepper-item ${activeTabIndex === 5 ? "current" : activeTabIndex > 4 ? "completed" : "pending"}`} data-kt-stepper-element="nav">
          <div className="stepper-line w-40px"></div>
          <div className="stepper-icon w-40px h-40px">
            <i className="stepper-check fas fa-check"></i>
            <span className="stepper-number">5</span>
          </div>
          <div className="stepper-label">
            <h3 className="stepper-title">Completed</h3>
            <div className="stepper-desc fw-bold">Woah, we are here</div>
          </div>
        </div>

      </div>
    )
  }

  _renderPackageDetail(){
    let {
      packageObject,
    } = this.state

    return (
      <div id="package-ui">
        <h1 className="text-white package-title">{packageObject.subtitle}</h1>
        <h1 className="text-white package-subtitle" style={{whiteSpace: 'pre-line'}}>
          {packageObject.description}
        </h1>
        <ul className="text-white package-benefits">
          {packageObject.benefits.map(benefit => (
            <li className="package-benefit">{benefit.title}</li>
          ))}
        </ul>
      </div>
    )
  }

  render() {
    let {
      packageObject,
      activeTabIndex
    } = this.state

    let logoStyle = {}
    let className = 'branded-login'
    if(packageObject){
      logoStyle = {position: 'absolute', top: '10%'}
      className = ''
    }

    return (
      <>
        <div className={`d-flex flex-row-fluid flex-column flex-center p-10 pt-lg-20 ${className}`}>

          <Link to={"/register"} className="mb-10 mb-lg-20 text-center" style={logoStyle}>
            <img alt="Logo" src={window.General.Branding?.logos?.light?.original} style={{ width: packageObject ? '40%' : '60%' }}/>
          </Link>

          {
            (activeTabIndex < 1 && packageObject) &&
            this._renderPackageDetail()
          }

          {
            (activeTabIndex > 0 || !packageObject) &&
            this._renderSteps()
          }

        </div>
      </>
    )
  }

}
