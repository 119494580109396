import React from 'react'
import Modal from 'react-bootstrap/Modal'
import {CopyToClipboard} from 'react-copy-to-clipboard';


import Notify from "../../../utils/Notify"
import Backend from "../../../utils/Backend";

export default class RevealKey extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    if(props.show && !this.state.show){
      this._setSecretKey(props.rotate)
    }
    return {
      ...props,
    }
  }

  _setSecretKey(rotate){
    this._handleBackend(rotate)
      .then(secretKey => {
        this.props.onSecretKeyUpdated(secretKey)
        this.setState({
          secretKey
        })
      })
      .catch(error => {
        Notify.error(error.message)
        this.props.onHide()
      })
  }

  _handleBackend(rotate){
    if(rotate){
      return Backend.rotateSecretKey()
    }
    else{
      return Backend.createSecretKey()
    }
  }

  render() {
    let {
      show,
      secretKey
    } = this.state

    return (
      <Modal
        show={show}
        dialogClassName={'modal-dialog-centered modal-md'}
      >
        <Modal.Header closeButton>
          <Modal.Title>Secret Key</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Please keep your Secret Key safe. If exposed please rotate your Secret Key and contact support.</label>
                { secretKey &&
                  <>
                    <div className="input-group mt-3">
                      <input
                        type="text"
                        className="form-control"
                        readOnly=""
                        id="secretKey"
                        value={secretKey?.value}
                      />
                      <div className="input-group-append">
                        <CopyToClipboard
                          text={secretKey?.value}
                          onCopy={() => {
                            Notify.success(`Secret Key copied to clipboard`)
                          }}
                        >
                          <button
                            className="btn btn-primary"
                            id="copyButton"
                            data-clipboard-action="copy"
                            data-clipboard-target="#secretKey"
                          >
                            Copy Secret Key
                          </button>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <span className="text-danger font-weight-bold">Note** You must copy your secret key. It will not be saved</span>
                  </>
                }
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>

          <CopyToClipboard
            text={secretKey?.value}
            onCopy={() => {
              Notify.success(`Secret Key copied to clipboard`)
              this.props.onHide()
            }}
          >
            <button
              type="button"
              className={`btn btn-light-primary font-weight-bold`}
            >
              Copy & Close
            </button>
          </CopyToClipboard>

        </Modal.Footer>
      </Modal>
    )
  }
}

RevealKey.defaultProps = {
  confirmTitleText: "Confirm",
  theme: "primary",
  dismissTitleText: "Dismiss"
}
