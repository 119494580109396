import FetchHelper from './FetchHelper'
import AuthManager from './AuthManager'
import moment from "moment";
import General from './General';

export default class Backend {

  static updateAccount(data){
    data = JSON.parse(JSON.stringify(data))
    delete data.user.email
    return FetchHelper.patch(`${window.Api.CompanyMembers}/${AuthManager.currentUser.user.id}`, data)
  }

  static updateCompany(companyId, data){
    data = JSON.parse(JSON.stringify(data));
    return FetchHelper.patch(`${window.Api.CompanyInfo}/${companyId}`, data)
  }

  static saveVATDetails(data){
    data = JSON.parse(JSON.stringify(data))
    data.signature = data.signature ? data.signature.id : null
    return FetchHelper.post(window.Api.CompanyInfo, data)
  }

  static saveTaxDetails(data){
    return FetchHelper.post(window.Api.TaxInfo, data)
  }

  static uploadFile(file, endpoint, params){
    let data = new FormData()

    data.append("file", file, file.name)
    for(var key in params){
      data.append(key, params[key])
    }

    return FetchHelper.post(endpoint, data, true)
  }

  static connectIntegration(companyMember, param){
    return FetchHelper.post(`${window.Api.Integrations}${param}`, {company: companyMember.company.id})
  }

  static getFiling(id){
    return FetchHelper.get(`${window.Api.Filings}/${id}`)
  }

  static getCurrentFilings(){
    return FetchHelper.get(`${window.Api.Filings}/latest`)
  }

  static getFilings(taxNumberId, pageSize){
    return FetchHelper.get(`${window.Api.Filings}?tax_number_id=${taxNumberId}&page_size=${pageSize}&order_by=created_at&pagination_type=page`)
  }

  static addRole(role) {
    return FetchHelper.post(window.Api.Roles, role)
  }

  static deleteRole(role) {
    return FetchHelper.delete(`${window.Api.Roles}/${role.id}`)
  }

  static updateRole(role) {
    return FetchHelper.patch(`${window.Api.Roles}/${role.id}`, role)
  }

  static addTeamMember(teamMember){
    let data = JSON.parse(JSON.stringify(teamMember));
    return FetchHelper.post(window.Api.CompanyMembers, data);
  }

  static updateTeamMember(teamMember){
    let data = JSON.parse(JSON.stringify(teamMember));
    return FetchHelper.patch(
      `${window.Api.CompanyMembers}/${teamMember.user.id}`,
      data
    );
  }

  static deleteTeamMember(teamMember) {
    return FetchHelper.delete(`${window.Api.CompanyMembers}/${teamMember.user.id}`);
  }

  static getIntegrations(role){
    return FetchHelper.get(`${window.Api.Integrations}?paginated=false&role=${role}`)
  }

  static getIntegration(integration){
    return FetchHelper.get(`${window.Api.Integrations}/${integration?.id}`)
  }

  static addIntegration(data){
    data = JSON.parse(JSON.stringify(data));
    return FetchHelper.post(window.Api.Integrations, data);
  }

  static getProblematicProducts(integrationId, companyId){
    return FetchHelper.get(`${window.Api.Products}?integration_id=${integrationId}&company_id=${companyId}&problematic=true`)
  }

  static uploadDocuments(documents){
    let data = JSON.parse(JSON.stringify(documents));
    return FetchHelper.put(window.Api.Documents, data);
  }

  static startConversation(message){
    let data = JSON.parse(JSON.stringify(message));
    if(data.subject === ""){
      delete data.subject
    }
    return FetchHelper.post(window.Api.Conversations, data);
  }

  static getConversation(id){
    return FetchHelper.get(`${window.Api.Conversations}/${id}`)
  }

  static sendMessage(data) {
    return FetchHelper.post(`${window.Api.Messages}`, data)
  }

  static getDocument(documentId){
    return FetchHelper.get(`${window.Api.Documents}/${documentId}`)
  }

  static deleteDocument(documentId) {
    return FetchHelper.delete(`${window.Api.Documents}/${documentId}`)
  }

  static getTransaction(transactionId){
    return FetchHelper.get(`${window.Api.Records}/${transactionId}`)
  }

  static updateConversation(conversation){
    return FetchHelper.patch(`${window.Api.Conversations}/${conversation.id}`, conversation)
  }

  static addTaxNumber(tax) {
    let data = JSON.parse(JSON.stringify(tax));
    return FetchHelper.post(window.Api.TaxNumbers, data)
  }

  static deleteTaxNumber(tax) {
    return FetchHelper.delete(`${window.Api.TaxNumbers}/${tax.id}`)
  }

  static updateTaxNumber(tax) {
    let data = JSON.parse(JSON.stringify(tax));
    return FetchHelper.patch(`${window.Api.TaxNumbers}/${tax.id}`, data)
  }

  static generateReport(report){
    let data = JSON.parse(JSON.stringify(report));
    if(data.filing){
      data.filing = data.filing.id
    }
    if(data.date_from){
      data.date_from = moment(data.date_from).format('YYYY-MM-DD')
    }
    if(data.date_to){
      data.date_to = moment(data.date_to).format('YYYY-MM-DD')
    }
    return FetchHelper.post(window.Api.Reports, data)
  }

  static getPackage(pkg){
    return FetchHelper.get(`${window.Api.Packages}/${pkg}`, false)
  }

  static uploadTransactions(transactions){
    let data = JSON.parse(JSON.stringify(transactions));
    return FetchHelper.post(window.Api.Ingestions, data);
  }

  static getSecretKey() {
    return FetchHelper.get(`${window.Api.SecretKey}`);
  }

  static createSecretKey() {
    return FetchHelper.post(`${window.Api.SecretKey}`);
  }

  static rotateSecretKey() {
    return FetchHelper.patch(`${window.Api.SecretKey}`);
  }

  static getBranding(){
    const brandingDomain = General.getBaseDomainName()
    return FetchHelper.get(`${window.Api.Branding}?url=${brandingDomain}`,false)
  }

}
