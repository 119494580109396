import { forwardRef, useState } from "react";
import ShowFormError from "./ShowFormError";

const TextInputField = forwardRef(
  (
    {
      label,
      errorMsg,
      className,
      labelClass,
      outerContainerClassName,
      type,
      link,
      isRequired,
      ...props
    },
    ref
  ) => {
    const [inputType, setInputType] = useState(type || "text");

    const toggleInputType = (type) => {
      setInputType(type);
    };

    return (
      <>
        <div
          className={`form-group flex-wrap ${outerContainerClassName}`}
        >
          {!!label?.length && (
            <label
              className={`col-sm-12 col-md-12 col-lg-12 col-form-label ${labelClass} ${
                isRequired && "required"
              }`}
            >
              {label}
            </label>
          )}
          <div className="d-flex flex-grow-1">
            <div className="d-flex flex-column w-100">
              {link ? (
                <div className="input-group-prepend d-flex">
                  <span
                    className={`input-group-text t-span-border ${
                      link ? "t-custom-link" : ""
                    }`}
                  >
                    https://
                  </span>
                  <input
                    className={`form-control form-control-lg form-control-solid position-relative ${className} ${
                      link ? "custom-link" : ""
                    }`}
                    ref={ref}
                    {...props}
                    type={inputType}
                  />
                </div>
              ) : (
                <div className="w-100 position-relative">
                  <input
                    className={`form-control form-control-lg form-control-solid position-relative ${className}`}
                    ref={ref}
                    {...props}
                    type={inputType}
                  />
                  {type === "password" && (
                    <span
                      className={`btn btn-sm btn-icon position-absolute t-password-icon`}
                      data-kt-password-meter-control="visibility"
                      onClick={() =>
                        toggleInputType(
                          inputType === "text" ? "password" : "text"
                        )
                      }
                    >
                      {inputType === "password" ? (
                        <i className="bi bi-eye-slash fs-2"></i>
                      ) : (
                        <i className="bi bi-eye fs-2"></i>
                      )}
                    </span>
                  )}
                </div>
              )}
              <ShowFormError message={errorMsg} />
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default TextInputField;
