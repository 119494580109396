import React from "react"
import AuthManager from "../../../utils/AuthManager";
import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";
import AsyncStorage from "../../../utils/AsyncStorage";
import ConfirmationModal from "../modals/Confirmation";
import RevealKey from "../modals/RevealKey";

export default class Api extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser
    }
  }

  componentDidMount() {
    this._setup()
  }

  _setup(){
    this.setState({isLoading: true})
    Backend.getSecretKey().then(secretKey => {
      this.setState({
        secretKey,
        isLoading: false
      })
    }).catch(error => {
      this.setState({isLoading: false})
    })
  }

  render() {
    let {
      secretKey,
      isLoading,
      rotateSecretKey,
      showRevealKeyModal,
      showRevealConfirmationModal,
      showRotateConfirmationModal,
    } = this.state

    let className = ""

    if(isLoading){
      className = "overlay overlay-block"
    }

    return (
      <>
        <div className={`card mb-5 mb-xl-10 ${isLoading && className}`} id="kt_profile_details_view">
          <div className="card-header border-0">
            <div className="card-title m-0">
              <h3 className="fw-bold m-0">Api</h3>
            </div>
          </div>
          <div id="kt_account_profile_details_form" className="form fv-plugins-bootstrap5 fv-plugins-framework">
            <div className={`card-body border-top p-9`}>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-semibold fs-6">Secret Key</label>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-9 fv-row fv-plugins-icon-container">
                      <input
                        type="text"
                        name="first_name"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="First name"
                        value={secretKey?.truncated || "*****************"}
                        disabled
                        style={{backgroundImage: 'url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAfBJREFUWAntVk1OwkAUZkoDKza4Utm61iP0AqyIDXahN2BjwiHYGU+gizap4QDuegWN7lyCbMSlCQjU7yO0TOlAi6GwgJc0fT/fzPfmzet0crmD7HsFBAvQbrcrw+Gw5fu+AfOYvgylJ4TwCoVCs1ardYTruqfj8fgV5OUMSVVT93VdP9dAzpVvm5wJHZFbg2LQ2pEYOlZ/oiDvwNcsFoseY4PBwMCrhaeCJyKWZU37KOJcYdi27QdhcuuBIb073BvTNL8ln4NeeR6NRi/wxZKQcGurQs5oNhqLshzVTMBewW/LMU3TTNlO0ieTiStjYhUIyi6DAp0xbEdgTt+LE0aCKQw24U4llsCs4ZRJrYopB6RwqnpA1YQ5NGFZ1YQ41Z5S8IQQdP5laEBRJcD4Vj5DEsW2gE6s6g3d/YP/g+BDnT7GNi2qCjTwGd6riBzHaaCEd3Js01vwCPIbmWBRx1nwAN/1ov+/drgFWIlfKpVukyYihtgkXNp4mABK+1GtVr+SBhJDbBIubVw+Cd/TDgKO2DPiN3YUo6y/nDCNEIsqTKH1en2tcwA9FKEItyDi3aIh8Gl1sRrVnSDzNFDJT1bAy5xpOYGn5fP5JuL95ZjMIn1ya7j5dPGfv0A5eAnpZUY3n5jXcoec5J67D9q+VuAPM47D3XaSeL4AAAAASUVORK5CYII=&quot;)', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundSize: '16px 18px', backgroundPosition: '98% 50%', cursor: 'auto'}}
                      />
                      <div className="fv-plugins-message-container invalid-feedback"/>
                    </div>
                    <div className="col-lg-3 fv-row fv-plugins-icon-container">
                      { !secretKey &&
                        <button
                          className="btn btn-outline-danger"
                          onClick={() => this.setState({ showRevealConfirmationModal: true })}
                        >
                          Reveal
                        </button>
                      }
                      { secretKey &&
                        <button
                          className="btn btn-outline-danger"
                          onClick={() => this.setState({ showRotateConfirmationModal: true })}
                        >
                          Rotate
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationModal
          show={showRevealConfirmationModal}
          title={"Reveal Secret Key"}
          body={"This key can only be revealed once to keep your account secure. If you lose it, you must rotate the key."}
          onConfirm={() => this.setState({ showRevealConfirmationModal: false, showRevealKeyModal: true, rotateSecretKey: false })}
          confirmTitleText={"Reveal Key"}
          theme={"danger"}
          dismissTitleText={"Close"}
          onHide={() => {
            this.setState({
              showRevealConfirmationModal: false,
              rotateSecretKey: false
            })
          }}
        />
        <ConfirmationModal
          show={showRotateConfirmationModal}
          title={"Rotate Secret Key"}
          body={"Any applications/integrations depending on the current key will no longer work"}
          onConfirm={() => this.setState({ showRotateConfirmationModal: false, showRevealKeyModal: true, rotateSecretKey: true })}
          confirmTitleText={"Rotate Key"}
          theme={"danger"}
          dismissTitleText={"Close"}
          onHide={() => {
            this.setState({
              showRotateConfirmationModal: false,
              rotateSecretKey: false
            })
          }}
        />
        <RevealKey
          show={showRevealKeyModal}
          rotate={rotateSecretKey}
          onSecretKeyUpdated={secretKey => this.setState({ secretKey}) }
          onHide={() => {
            this.setState({
              showRevealKeyModal: false,
              rotateSecretKey: false
            })
          }}
        />
      </>
    )
  }

}
